import { Box, Heading, Button, Text } from "@chakra-ui/react";

const SeventhSection = () => {
  return (
    <>
    {/* <Box backgroundColor="#10104B"> */}
      <Box className='seventhSectionBg'>
        <Box mx={{lg: 20, md: 10, base: 10 }} pt={{lg: 40, md: 10, base: 10 }} style={{textAlign:window.screen.width <577? 'center':''}}>
          <Heading fontSize="45px" lineHeight="65px" fontWeight={700}>Dedicated Support
            <br /> Just For You
          </Heading>
          <Text mt={5} fontSize="16px">
          Qonvey is equipped with an efficient, experienced customer <br /> support team that are readily available to give you the<br /> support you require. 
          </Text>
          <Button mt={20} backgroundColor="#1112BA" _hover={{ bg: '#1112BA' }}>
            <a href="mailto:info@qonvey.app">Contact Us</a>
            </Button>
        </Box>
      </Box>
    </>
  );
};

export default SeventhSection;
