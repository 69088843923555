import React, { useEffect } from 'react'
import Footer from './Footer'
import Header from './Header'
import { TermsAndConditions } from './policyHtml'
import { Box, Text } from '@chakra-ui/react'
function TermAndCondition() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [])
    return (
        <div style={{backgroundColor:'#18182d'}}>
            <Header/>
          <Box  m={5} >  <Text fontSize='3xl' backgroundColor='#18182d' as='b' >Terms & Conditions</Text></Box>
          <Box  ml={10} ><div className="content" style={{backgroundColor:'#18182d'}} dangerouslySetInnerHTML={{__html: TermsAndConditions}}></div></Box>
            <Footer/>
        </div>
    )
}

export default TermAndCondition
