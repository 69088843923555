import {
  Box,
  Button,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";
// import './mcFormStyles.scss';
import MailchimpSubscribe from "react-mailchimp-subscribe";

const CustomForm = ({ status, message, onValidated, open, setOpen }) => {
  const [email, setEmail] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email,
      });
  };
 
  return (
    <Modal isOpen={open} size="xl" textAlign={"center"}>
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px) hue-rotate(90deg)"
      />
      <ModalContent
        pt={20}
        pb={10}
        textAlign={"center"}
        alignItems="center"
        minWidth={{ base: 1, md: "1000px", lg: "1000px", sm: "300px" }}
      >
        <Heading
          size="xl"
          maxW="30rem"
          mt={{ base: 1, md: 1, lg: "3" }}
          color="#10104B"
        >
          Don't miss the update.
        </Heading>
        <Heading
          size="sm"
          maxW="30rem"
          mt={{ base: 1, md: 1, lg: "3" }}
          color="#10104B"
        >
          Enter your email to receive all update and discounts in your mail box.
        </Heading>
        <ModalCloseButton bg="#10104B" onClick={() => setOpen(false)} />
        {status ==='success' ?<ModalBody textAlign={"center"}>
         
            <Box mt={5} mb={5}>
            <Heading
          size="xl"
          maxW="30rem"
          mt={{ base: 1, md: 1, lg: "3" }}
          color="#10104B"
        >
         Thank you for subscribing!.
        </Heading>
            </Box>

            <Button
              bg="#10104B"
              minWidth={{
                base: "100px",
                md: "200px",
                lg: "200px",
                sm: "200px",
              }}
              minHeight={{ base: "30px", md: "50px", lg: "50px" }}
              label="subscribe"
              onClick={()=>setOpen(false)}
            >
              OK
            </Button>
         
        </ModalBody>:<ModalBody textAlign={"center"}>
          <form className="mc__form" onSubmit={(e) => handleSubmit(e)}>
            <Box mt={5} >
              <Input
                color="#10104B"
                textAlign="center"
                minWidth={{
                  base: "300px",
                  md: "500px",
                  lg: "500px",
                  sm: "300px",
                }}
                minHeight={{ base: "30px", md: "50px", lg: "50px" }}
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                value={email}
                placeholder="your@email.com"
                isRequired
              />
            </Box>
{status ==='error' &&<Heading
          size="sm"
          maxW="30rem"
          mt={{ base: 1, md: 1, lg: "3" }}
          color="#10104B"
        >Please enter valid email address.</Heading>}
            <Button
            mt={5}
              bg="#10104B"
              minWidth={{
                base: "100px",
                md: "300px",
                lg: "300px",
                sm: "300px",
              }}
              minHeight={{ base: "30px", md: "50px", lg: "50px" }}
              label="subscribe"
              type="submit"
            >
              Submit
            </Button>
          </form>
        </ModalBody>}
      </ModalContent>
    </Modal>
  );
};

const MailchimpFormContainer = ({ open, setOpen }) => {
  const postUrl = `https://app.us10.list-manage.com/subscribe/post?u=67008f86a1428c211a3bb461f&amp;id=696e21f3ba&amp;f_id=00ac33e2f0`;
  return (
    <Box w="25%" className="mc__form-container">
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
            open={open}
            setOpen={setOpen}
          />
        )}
      />
    </Box>
  );
};

export default MailchimpFormContainer;
