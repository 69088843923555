import React, { useEffect } from 'react'
import Footer from './Footer'
import Header from './Header'
import { PolicyHtml } from './policyHtml'
import { Box, Text } from '@chakra-ui/react'
import './policy.css'
function Policy() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [])
    return (
        <div style={{backgroundColor:'#18182d'}}>
            <Header/>
          <Box  m={5} >  <Text fontSize='3xl' backgroundColor='#18182d' as='b' > Privacy Policy</Text></Box>
          <Box  mr={5} ><div className="content" style={{backgroundColor:'#18182d'}} dangerouslySetInnerHTML={{__html: PolicyHtml}}></div></Box>
            <Footer/>
        </div>
    )
}

export default Policy
