import { Box, Center, Heading, Text, SimpleGrid, Image, Stack, Container} from '@chakra-ui/react';
import Header from './Components/Header';
import SecondSection from './Components/SecondSection';
import ThirdSection from './Components/ThirdSection';
import FourthSection from './Components/FourthSection';
import FifthSection from './Components/FifthSection';
import SixthSection from './Components/SixthSection';
import SeventhSection from './Components/SeventhSection';
import Section8 from './Components/Section8';
import Footer from './Components/Footer';
import FAQs from './Components/FAQs';
import MailchimpFormContainer from './Components/EmailSubscription';
import { useEffect, useState } from 'react';

const Main = ({open,setOpen}) => { 
  
  return (
    <Box backgroundColor="#10104B">
      <Header />
      <MailchimpFormContainer open={open} setOpen={setOpen}/>
      {/* header section */}
      <Box className='bgImg'>
        <Container style={{textAlign: 'center'}}>
          <Stack textAlign={'center'} spacing={{ base: 2, md: 10 }} py={{ base: 40, md: 40, lg: 40 }} >
            <Heading fontWeight={600} style={{ marginTop: '-2rem'}} fontSize={{ base: '2xl', md: '5xl' }} lineHeight={'110%'}>Deliver goods seamlessly to any location around you<br /></Heading>
            <Text size="xl" fontWeight={'bold'} style={{ marginLeft: '1rem'}}>Qonvey is a modernized delivery platform that manages deliveries for business organizations and individual clients.Faster, professional and efficient delivery platform you can rely on.</Text>
            <Stack spacing={3} align={'center'} alignSelf={'center'}>
              <Center>
                <SimpleGrid columns={{ base: 2, md:2, lg:2}} mt={5}>
                  <Image src={"https://res.cloudinary.com/qonvex-tech/image/upload/v1653431228/Qonvey%20LandingPage/googleplay_sq2djh.png"} my={{ base: 0, md:0, lg:0}} onClick={()=>window.open('https://play.google.com/store/apps/details?id=com.qonvey')}
            style={{cursor:'pointer'}} alt='Download android app'/>
                  <Image src={"https://res.cloudinary.com/qonvex-tech/image/upload/v1653431227/Qonvey%20LandingPage/apple_qxbmil.png"} ml={{ base: 2, md:2, lg:2}} onClick={()=>window.open('https://apps.apple.com/ng/app/qonvey/id6443527939')}  style={{cursor:'pointer'}} alt='Download ios app'/>
                </SimpleGrid>
              </Center>
            </Stack>
          </Stack>
        </Container>
      </Box>
      {/* header section ends */}

      {/* other sections */}
      <SecondSection />
      <section id='users'>
        <ThirdSection />
      </section>
        <FourthSection />
      <section id='riders'>
        <FifthSection />
      </section>
        <section id="about">
      <SixthSection />
      </section>
      <section id='contact'>
        <SeventhSection />
      </section>
      <Section8 />
      <FAQs />
      <Footer />
      {/* other sections ends */}
    </Box>
  )
}

export default Main;
