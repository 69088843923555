import { Grid, GridItem, Image, Text, Flex, Box } from "@chakra-ui/react";
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
  return (
    <Box py={5} px={5} w={"full"} style={{ backgroundColor: "#0B0D17" }} >
      <Grid
        templateColumns="repeat(4, 1fr)"
        py={{ base: 1, md: 1, lg: 5 }}
        px={5}
        gap={4}
        maxWidth="1222px"
        mx="auto"
      >
        <GridItem
          columns={{ base: 1, md: 2, lg: 2 }}
          colSpan={{ base: 1, md: 1, lg: 3 }}
        >
          <Image
            src="https://res.cloudinary.com/qonvex-tech/image/upload/v1639927190/Qonvey%20LandingPage/Logo_io7jew.png"
            alt="qonvey logo"
            onClick={()=>window.location.reload()}
            style={{cursor:'pointer'}}
          />
          <Box>
            <Flex py={5} flexDirection={{ base: "column", lg: "row" }}>
              <HashLink smooth to="/page#riders">
                <Text style={{ color: "white" }} my={{ base: 2, md: 2, lg: 0 }} mr="24px">Riders</Text>
              </HashLink>
              <HashLink smooth to="/page#about">
              <Text mr="24px" my={{ base: 2, md: 2, lg: 0 }}>About</Text>
              </HashLink>
              <HashLink smooth to="/page#users">
              <Text  my={{ base: 2, md: 2, lg: 0 }} >Users</Text>
              </HashLink>
              <HashLink smooth to="/page#contact">
              <Text ml={{base: 0, md:0, lg: "24px"}} my={{ base: 2, md: 2, lg: 0 }} width={{ base: 100, md:100, lg: 100}}>Contact Us</Text>
              </HashLink>
              <HashLink smooth to="/privacy-policy">
              <Text ml={{base: 0, md:0, lg: "10px"}} my={{ base: 2, md: 2, lg: 0 }} width={{ base: 100, md:100, lg: 100}} style={{whiteSpace:'nowrap'}}>Privacy Policy</Text>
              </HashLink>
              <HashLink smooth to="/terms-and-conditions">
              <Text ml={{base: 0, md:0, lg: "24px"}} my={{ base: 2, md: 2, lg: 0 }} width={{ base: 100, md:100, lg: 100}} style={{whiteSpace:'nowrap'}}>Terms & Conditions</Text>
              </HashLink>
            </Flex>
          </Box>
          <Box sx={{ minWidth: 280 }} mt={{ base: 10, md: 10, lg:0}}>
            <Text>© {new Date().getFullYear()} Qonvex Tech Limited. All rights reserved</Text>
          </Box>
        </GridItem>
        <GridItem colStart={{ base: 1, md: 1, lg: 4 }} colEnd={6} py={5}>
          <Text>Get the App</Text>
          <Image
            src="https://res.cloudinary.com/qonvex-tech/image/upload/v1640024050/Qonvey%20LandingPage/Layer_2_bw3xxm.png"
            my={3}
            onClick={()=>window.open('https://play.google.com/store/apps/details?id=com.qonvey')}
            style={{cursor:'pointer'}}
            alt="Download android app"
          />
          <Image
            src="https://res.cloudinary.com/qonvex-tech/image/upload/v1640024036/Qonvey%20LandingPage/App_Store_Badge_huyhqu.png"
            onClick={()=>window.open('https://apps.apple.com/ng/app/qonvey/id6443527939')}  style={{cursor:'pointer'}}
            my={3}
            alt="Download ios app"
          />
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Footer;
