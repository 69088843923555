import { SimpleGrid, Image, Box, Heading, Text } from '@chakra-ui/react';

const FourthSection = () => {
  return (
    <section style={{backgroundColor: '#18182D'}}>
      <SimpleGrid columns={{ base: 1, md:2, lg:2}} spacing={10} py="96px" px={10} maxWidth="1440px" mx="auto" >
        <Image src="https://res.cloudinary.com/qonvex-tech/image/upload/v1639927191/Qonvey%20LandingPage/box_x0j1uw.png" alt="guy carrying box" />
        <Box ml={{ base: 0, md:0, lg:10}} style={{textAlign:window.screen.width <577? 'center':''}}>
          <Heading maxW='30rem' fontWeight={700} mt={{ base: 1, md:1, lg:'32'}}>Deliver More! With our multiple delivery feature</Heading>
          <Text mt={5} maxW='30rem'>Our service offers you the freedom to deliver more.
            Get the benefit of one rider, multiple deliveries, and an economical rate to leverage your deliveries.</Text>
        </Box>
      </SimpleGrid>  
    </section>
  )
}

export default FourthSection;
