export const PolicyHtml=`	

	<ol style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: upper-latin;'>
		<li style="box-sizing: border-box; margin-left: 32.7pt; text-align: justify; padding-left: 3.3pt; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">INTRODUCTION</span></li><span style="box-sizing: border-box; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);">&nbsp;</span>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;"></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">At Qonvey, we understand that when you share your personal information with us, you place your trust in our ability to protect it. That&apos;s why we prioritize transparency and education regarding our privacy procedures. We want to ensure that you are aware of the types of personal data we collect, how we use and share it, and your options with regard to your data. Our commitment to your privacy and security begins with this notice.</span></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;</span></p>
	<ol start="2" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: upper-latin;'>
		<li style="box-sizing: border-box; margin-left: 32.35pt; text-align: justify; padding-left: 3.65pt; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">OVERVIEW AND SCOPE</span></li><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;</span>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;"></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">This notice applies to all Qonvey users in Lagos, Nigeria, including those who use Qonvey&apos;s applications, websites, features, or other services. They include but are not limited to:</span></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;</span></p>
	<ol style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: lower-roman;'>
		<li style="box-sizing: border-box; margin-left: 59pt; text-align: justify; padding-left: 13pt; font-family: Candara; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">Riders: those who request or receive dispatch, including those who receive dispatch requested by someone else.</span></li><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;</span>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 72pt; text-align: justify;"></p>
	<ol start="2" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: lower-roman;'>
		<li style="box-sizing: border-box; margin-left: 59pt; text-align: justify; padding-left: 13pt; font-family: Candara; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">Drivers: persons that send Riders either directly or through partner delivery organizations.</span></li><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;</span>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 72pt; text-align: justify;"></p>
	<ol start="3" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: lower-roman;'>
		<li style="box-sizing: border-box; margin-left: 59pt; text-align: justify; padding-left: 13pt; font-family: Candara; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">Individuals who request or receive food, or other items and services for delivery or pick-up.</span></li><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;</span>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 72pt; text-align: justify;"></p>
	<ol start="4" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: lower-roman;'>
		<li style="box-sizing: border-box; margin-left: 59pt; margin-bottom: 8pt; text-align: justify; padding-left: 13pt; font-family: Candara; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">Individuals who provide delivery services to clients are referred to as delivery personnel.</span></li><p style="box-sizing:  border-box; margin-left: 36pt;font-family: Candara; color: rgb(255, 255, 255);">This notice also applies to personal data collected by Qonvey in conjunction with its services, such as contact information from Qonvey for Business account users or personal data related to mapping technology. &quot;Users&quot; refers to all people subject to this notice.</p>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;"></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">Qonvey doesn&apos;t handle Nigerian users&apos; personal data for Qonvey&apos;s or third parties&apos; legitimate interests. Instead, such data is handled to fulfill our duties under agreements with users or with their consent.</span></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">Personal information of Guest Users (those who order or receive trips, deliveries, or pickups through partner websites or apps) is used solely for requested services and safety and security purposes. It may be shared with third parties for security, customer assistance, research and development, legal actions, and obligations. This information may be accessible to the account owner. Contact www.qonvey.app with questions, comments, or data requests.</span></p>
	<ol start="3" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: upper-latin;'>
		<li style="box-sizing: border-box; margin-left: 31.85pt; margin-bottom: 8pt; text-align: justify; padding-left: 4.15pt; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">DATA COLLECTIONS AND USES</span></li><p style="margin-left: 36pt;"><span style="box-sizing: border-box; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);">Q:</span><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;</span><span style="box-sizing: border-box; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);">What type of personal information does Qonvey collect from its users?</span></p>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 18pt; text-indent: 18pt; text-align: justify;"></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);">A:</span><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;Qonvey collects various types of personal information from its users, including user profile and checkout information, location data, demographic information, background investigation and identification verification for drivers and delivery personnel, and data about usage of Qonvey&apos;s services. The company may also deduce demographic information from other user data and gather user material when users contact Qonvey customer service. Additionally, Qonvey collects transaction information related to the use of its services, such as the type of services requested or provided, order details, payment transaction information, delivery information, and payment method.</span></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);">Q:</span><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;</span><span style="box-sizing: border-box; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);">How do we use personal data?</span></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);">A:</span><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;Qonvey uses personal data to provide safe and convenient transportation, delivery, and other services. We also use the data to enhance safety, provide customer support, conduct research and development, enable communications between users, and for legal proceedings.</span></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">We do not sell or share user personal data with third parties for direct marketing without consent.</span></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">Personal data is used to create/update accounts, enable transportation and delivery services, process payments, personalize accounts, facilitate insurance, vehicle, invoicing, or financing solutions, troubleshoot software bugs, conduct data analysis, testing, and research, and monitor usage and activity trends.</span></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">We collect and use location data to fulfill our obligations under our Terms of Use or other agreements with users. To object to personalized recommendations, please see the section of this notice titled &quot;</span><span style="box-sizing: border-box; font-family: Candara; font-weight: bold; font-style: italic; color: rgb(255, 255, 255);">Choice and transparency</span><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">.&quot;</span></p>
	<ol start="4" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: upper-latin;'>
		<li style="box-sizing: border-box; margin-left: 32.87pt; text-align: justify; padding-left: 3.13pt; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">SAFETY AND SECURITY</span></li><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;</span>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;"></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">We use personal data to enhance the safety and security of our services and users. This includes verifying users&apos; identity, detecting unsafe driving behaviour, preventing, and combating fraud, and managing interpersonal conflicts. We use facial recognition technology to prevent unauthorized use of our services and may use selfies to verify mask or safety gear compliance. User feedback is used to encourage compliance and avoid high-risk matches. We share relevant information with third-party conflict management companies. These activities are necessary to fulfill our obligations to users and for the legitimate safety and security interests of Qonvey and other parties.</span></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;</span></p>
	<ol start="5" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: upper-latin;'>
		<li style="box-sizing: border-box; margin-left: 31.57pt; text-align: justify; padding-left: 4.43pt; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">CUSTOMER SUPPORT</span></li><span style="box-sizing: border-box; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);">&nbsp;</span>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;"></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">We use personal data for customer support and research and development purposes, including to improve our services and develop new features. This helps us enhance the user experience, ensure the safety and security of our services, and address user concerns. Qonvey performs these activities to fulfill our obligations to users and for our legitimate interests in developing new services and features.</span></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;</span></p>
	<ol start="6" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: upper-latin;'>
		<li style="box-sizing: border-box; margin-left: 31.17pt; margin-bottom: 8pt; text-align: justify; padding-left: 4.83pt; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">ENABLING COMMUNICATIONS BETWEEN USERS</span></li><p style="box-sizing: border-box; margin-left: 36pt; font-family: Candara; color: rgb(255, 255, 255);">Qonvey may use personal data to communicate with users about our services, including verifying pick-up locations and informing users about lost items or orders. We may also use personal data for marketing purposes, including sending communications about Qonvey services, promotions, and events, and providing recommendations for products and services offered by Qonvey partners based on users&apos; past orders. We use data to personalize and improve marketing communications and may share data with advertising partners. Users can opt-out of certain marketing communications. Qonvey performs these activities on the basis of legitimate interests or user consent.</p>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;"></p>
	<ol start="7" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: upper-latin;'>
		<li style="box-sizing: border-box; margin-left: 32.61pt; text-align: justify; padding-left: 3.39pt; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">NON-MARKETING COMMUNICATIONS</span></li><span style="box-sizing: border-box; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);">&nbsp;</span>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;"></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">Qonvey may use personal data to provide users with receipts, communicate changes to terms or policies, and send other non-marketing communications. Qonvey performs these activities to fulfill its obligations to users and for legitimate interests in keeping users informed about events that may affect their use of Qonvey services.</span></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;</span></p>
	<ol start="8" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: upper-latin;'>
		<li style="box-sizing: border-box; margin-left: 32.96pt; text-align: justify; padding-left: 3.04pt; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">LEGAL PROCEEDINGS AND REQUIREMENTS</span></li><span style="box-sizing: border-box; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);">&nbsp;</span>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;"></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">We may use personal data to investigate and address claims or disputes related to Qonvey&apos;s services, comply with legal requirements or respond to governmental requests, including from law enforcement.</span></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;</span></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">Qonvey performs the above activities based on our legitimate interests in investigating and responding to claims and disputes, and to comply with applicable legal requirements.</span></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;</span></p>
	<ol start="9" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: upper-latin;'>
		<li style="box-sizing: border-box; margin-left: 28.84pt; margin-bottom: 8pt; text-align: justify; padding-left: 7.16pt; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">AUTOMATED DECISION-MAKING</span></li><p style="box-sizing: border-box; font-family: Candara; margin-left: 36pt;color: rgb(255, 255, 255);">We use personal data to enable dynamic pricing, match users with available drivers/delivery persons, determine user ratings, flag unsafe or fraudulent behaviour, and identify cancellation fees earned or induced through fraud. We may also use driver and rider data to help avoid pairings that may result in increased risk of conflict. We perform these activities on the grounds of our legitimate interests in providing efficient and safe services to our users.</p>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;"></p>
	<ol start="10" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: upper-latin;'>
		<li style="box-sizing: border-box; margin-left: 30.17pt; margin-bottom: 8pt; text-align: justify; padding-left: 5.83pt; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">DATA SHARING AND DISCLOSURE</span></li><span style="box-sizing: border-box; font-family: Candara; margin-left: 36pt; color: rgb(255, 255, 255);">Qonvey may share personal data in various situations:</span>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; text-indent: 36pt; text-align: justify;"></p>
	<ol style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: lower-roman;'>
		<li style="box-sizing: border-box; margin-left: 59pt; text-align: justify; padding-left: 13pt; font-family: Candara; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">With other users, such as riders&apos; first name, rating, and pickup and/or dropoff locations with drivers, and recipients&apos; first name, delivery address, and order information with restaurants or merchants.</span></li><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;</span>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 72pt; text-align: justify;"></p>
	<ol start="2" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: lower-roman;'>
		<li style="box-sizing: border-box; margin-left: 59pt; text-align: justify; padding-left: 13pt; font-family: Candara; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">At the user&apos;s request, such as sharing data with emergency services, business partners, or other people at the user&apos;s request.</span></li><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;</span>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 72pt; text-align: justify;"></p>
	<ol start="3" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: lower-roman;'>
		<li style="box-sizing: border-box; margin-left: 59pt; text-align: justify; padding-left: 13pt; font-family: Candara; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">With the general public, such as questions or comments from users submitted through public forums like Qonvey blogs and social media pages.</span></li><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;</span>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 72pt; text-align: justify;"></p>
	<ol start="4" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: lower-roman;'>
		<li style="box-sizing: border-box; margin-left: 59pt; text-align: justify; padding-left: 13pt; font-family: Candara; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">With the Qonvey account owner, such as sharing order or trip information, including location data, with the owner of the account.</span></li><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;</span>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 72pt; text-align: justify;"></p>
	<ol start="5" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: lower-roman;'>
		<li style="box-sizing: border-box; margin-left: 59pt; text-align: justify; padding-left: 13pt; font-family: Candara; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">For legal reasons or in the event of a dispute, such as sharing personal data with law enforcement officials or other third parties as necessary to enforce our Terms of Service or protect Qonvey&apos;s rights or property.</span></li><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;</span>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 72pt; text-align: justify;"></p>
	<ol start="6" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: lower-roman;'>
		<li style="box-sizing: border-box; margin-left: 59pt; text-align: justify; padding-left: 13pt; font-family: Candara; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">With consent, if Qonvey notifies the user and they consent to the sharing.</span></li><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;</span>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 72pt; text-align: justify;"></p>
	<ol start="11" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: upper-latin;'>
		<li style="box-sizing: border-box; margin-left: 32.25pt; margin-bottom: 8pt; text-align: justify; padding-left: 3.75pt; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">DATA RETENTION AND DELETION</span></li><p style="box-sizing: border-box; font-family: Candara; margin-left: 36pt;color: rgb(255, 255, 255);">Qonvey retains user data for different periods of time depending on the type of data, user category, and purposes for which it was collected. Users can delete their accounts anytime through the Qonvey app. However, we may retain user data for legal or regulatory requirements, safety, security, and fraud prevention purposes or for unresolved claims or disputes. Drivers and delivery persons&apos; data is kept for at least 7 years, while riders and order recipients&apos; data is deleted within 90 days of a deletion request, except when retention is necessary for the reasons stated above.</p>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;"></p>
	<ol start="12" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: upper-latin;'>
		<li style="box-sizing: border-box; margin-left: 31.11pt; margin-bottom: 8pt; text-align: justify; padding-left: 4.89pt; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">CHOICE AND TRANSPARENCY</span></li><p style="box-sizing: border-box; font-family: Candara; margin-left: 36pt;color: rgb(255, 255, 255);">Qonvey provides users with the ability to access and manage the data collected by Qonvey through privacy settings, device permissions, in-app ratings pages, and marketing choices. Users can also request access to their data, make updates to their accounts, or request deletion of their accounts. Additionally, users can request that Qonvey restricts its processing of their personal data.</p>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;"></p>
	<ol start="13" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: upper-latin;'>
		<li style="box-sizing: border-box; margin-left: 35.27pt; margin-bottom: 8pt; text-align: justify; padding-left: 0.73pt; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">PRIVACY SETTINGS</span></li><p style="box-sizing: border-box; font-family: Candara; margin-left: 36pt;color: rgb(255, 255, 255);">In the Qonvey app, riders and order recipients can control their preferences for location data collection, emergency data sharing, and notifications via the Settings &gt; Privacy menu.</p>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;"></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">They can enable/disable Qonvey to collect location data and share their live location with drivers. They can also enable/disable emergency data sharing with emergency services.</span></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">Notifications related to trip status and account activity cannot be disabled, but users can choose how they receive them. Users may also opt-in to receive push notifications about discounts and news from Qonvey or communications from certain restaurants and merchants, which can be disabled through the Settings menu.</span></p>
	<ol start="14" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: upper-latin;'>
		<li style="box-sizing: border-box; margin-left: 33.08pt; text-align: justify; padding-left: 2.92pt; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">DEVICE PERMISSIONS</span></li><span style="box-sizing: border-box; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);">&nbsp;</span>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;"></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">Most mobile device platforms (iOS, Android, etc.) have defined certain types of device data that apps cannot access without the device owner&rsquo;s permission, and these platforms have different methods for how that permission can be obtained. Please check the available settings on your device or check with your provider.</span></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);">&nbsp;</span></p>
	<ol start="15" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: upper-latin;'>
		<li style="box-sizing: border-box; margin-left: 33.44pt; margin-bottom: 8pt; text-align: justify; padding-left: 2.56pt; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">IN-APP RATINGS PAGES</span></li><p style="box-sizing: border-box; font-family: Candara; margin-left: 36pt;color: rgb(255, 255, 255);">Qonvey allows drivers and riders to rate each other on a scale of 1 to 5 after each trip.</p>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;"></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">The average rating is displayed on the user&apos;s account for others to see. This accountability system helps to create a respectful and safe environment for both drivers and riders. Riders can view their average rating in the Qonvey app&apos;s main menu, while drivers can see their average rating by tapping their profile photo in the Qonvey Driver app. Additionally, delivery persons may also be rated by order recipients, restaurants, and merchants.</span></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;</span></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">&nbsp;</span></p>
	<ol start="16" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: upper-latin;'>
		<li style="box-sizing: border-box; margin-left: 31.88pt; margin-bottom: 8pt; text-align: justify; padding-left: 4.12pt; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">MARKETING CHOICES</span></li><p style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);margin-left: 36pt;">Users can opt out of receiving certain marketing communications and control the use of their data for marketing through ad settings, marketing email/message preferences, and unsubscribe instructions provided in messages. Qonvey may still send non-promotional communications to users who have opted out of marketing communications.</p>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;"></p>
	<ol start="17" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: upper-latin;'>
		<li style="box-sizing: border-box; margin-left: 33.44pt; margin-bottom: 8pt; text-align: justify; padding-left: 2.56pt; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">USER PERSONAL DATA REQUESTS</span></li><p style="box-sizing: border-box; font-family: Candara; margin-left: 36pt;color: rgb(255, 255, 255);">Qonvey provides users with various options to access, control, and inquire about their personal data. Users can access their data and trip/order history through the app, edit their account information, and delete their account anytime. Users may also request to restrict or object to the use of their data, and file a complaint with their country&apos;s data protection authority.</p>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;"></p>
	<ol start="18" style='box-sizing: border-box; padding-left: 0pt; margin: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: upper-latin;'>
		<li style="box-sizing: border-box; margin-left: 32.32pt; text-align: justify; padding-left: 3.68pt; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);"><span style="box-sizing: border-box;">UPDATES TO THIS NOTICE</span></li><span style="box-sizing: border-box; font-family: Candara; font-weight: bold; color: rgb(255, 255, 255);">&nbsp;</span>
	</ol>
	<p style="box-sizing: border-box; margin-top: 0pt; margin-bottom: 0pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, &quot;Liberation Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;"></p>
	<p style='box-sizing: border-box; margin-top: 0pt; margin-bottom: 8pt; color: rgb(255, 255, 255); font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; margin-left: 36pt; text-align: justify;'><span style="box-sizing: border-box; font-family: Candara; color: rgb(255, 255, 255);">We may update this notice and will notify users of significant changes through the Qonvey apps or email. We encourage users to periodically review this notice. Use of our services after an update constitutes consent to the updated notice, where permitted by law.</span></p>

`
export const TermsAndConditions=`<p>TERMS AND CONDITIONS OF QONVEY<br />
1. Contractual Relationship<br />
These Terms of Use (&ldquo;Terms&rdquo;) govern the access or use by you, an individual, from within Nigeria of applications, websites, content, products, and services (the &ldquo;Services&rdquo;) made available by Qonvex Tech., a private limited liability company established in Nigeria, having its offices at 6 Gbaja market flats Surulere Lagos., registered under the Corporate Affairs Commission (&ldquo;Qonvey&rdquo;).<br />
PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING THE SERVICES<br />
a. The user agrees to be bound by these Terms when accessing or using Qonvey&#39;s Services.<br />
b. Qonvey may terminate the Services or deny access for any reason.<br />
c. Supplemental terms may apply to certain Services, and they will be disclosed to the user.<br />
d. Qonvey may amend the Terms from time to time, and the user&#39;s continued access constitutes consent to the amended Terms.<br />
e. Qonvey&#39;s Privacy Policy governs the collection and use of personal information.<br />
f. Qonvey may provide necessary information to resolve complaints, disputes, or conflicts involving the user and a Third-Party Provider.<br />
g. Qonvey may share the user&#39;s contact and other necessary information with a claims processor or insurer in the event of a complaint, dispute, or conflict, such as an accident involving the user and a Third Party Provider, if such information is required to resolve the issue.<br />
h. Our collection and use of personal information in connection with the Services is as provided in Qonvey&rsquo;s Privacy Policy located at https://www.qonvey.com.<br />
2. The Services Qonvey&#39;s Services consist of a technology platform that allows users to arrange and schedule logistics services with independent Third-Party Providers through the Qonvey mobile app or website. The Services are intended for personal, non- commercial use, unless a separate written agreement with Qonvey states otherwise. Qonvey does not provide transportation or logistics services or operate as a transportation carrier. All transportation and logistics services offered through Qonvey are provided by independent Third-Party Contractors who are not employed by Qonvey or any of its affiliates.</p>

<p>3. License. Qonvey grants the user a limited, non-exclusive, non-sublicensable, revocable, and non-transferable license to access and use the Applications on their personal device solely for using the Services, and to access and use any content or information available through the Services for personal, non-commercial use. Qonvey and its licensors reserve all other rights not expressly granted in these Terms.<br />
4. Restrictions. You cannot remove copyright notices, modify, distribute, sell, or exploit the services without permission. You cannot reverse engineer or scrape the services, link to or frame any portion, or attempt to gain unauthorized access.<br />
5. Provision of the Services. The Services may be offered under different brands or options associated with logistics, including &quot;Qonvey,&quot; by Qonvey&#39;s subsidiaries and affiliates, or independent Third-Party Providers such as transportation companies or drivers with permits/licenses.<br />
6. Third Party Services and Content. The Services may include third party services and content, which are not controlled by Qonvey. Different terms and privacy policies may apply to these services, and Qonvey is not responsible for them. If you access the Services through applications developed for Apple iOS or Android, their international subsidiaries and affiliates are considered third-party beneficiaries and are not responsible for the provision or support of the Services. Your access to the Services through these devices is subject to the terms set by the applicable third-party beneficiary.<br />
7. Ownership. Qonvey and its licensors own the Services and all related rights. These terms and your use of the Services do not give you any rights to the Services or to use Qonvey&#39;s trademarks or logos, except for the limited license granted above.<br />
8. Your Use of the Services.<br />
a. User Accounts. To use most aspects of the Services, you need to register for an Account with accurate personal information and at least one valid payment method. You must be at least 18 years old. You are responsible for maintaining accurate and up-to- date Account information and keeping your username and password secure. Having invalid or expired payment information may result in the inability to use the Services or termination of these Terms. You can only have one Account unless otherwise allowed by Qonvey.<br />
b. User Requirements and Conduct. Persons under 18 years old cannot use the Service. You cannot let anyone else use your Account or allow anyone under 18 to receive transportation or logistics services unless they are with you. You cannot transfer your Account to someone else. Use the Services only for lawful purposes and do not cause any harm or damage to anyone. You may need to prove your identity to access or use the Services, and refusal may result in denial of access.</p>

<p>c. Text Messaging. When you create an Account, the Services may send you text messages as part of their normal business operation. You can opt-out of receiving these messages at any time by following the directions on the Qonvey website. However, opting out may affect your use of the Services.<br />
d. Promotional Codes. Qonvey may create Promo Codes that can be redeemed for credits or benefits related to the Services or a Third-Party Provider&#39;s services. You agree to use Promo Codes lawfully and only for the intended audience and purpose. Promo Codes cannot be duplicated, sold, or transferred unless permitted by Qonvey. They may be disabled by Qonvey at any time, expire prior to use, and are not valid for cash. Qonvey may withhold or deduct credits obtained through Promo Codes if they were used fraudulently or in violation of these Terms or Promo Code terms.<br />
e. User Provided Content. You can submit textual, audio, and/or visual content to Qonvey through the Services. By doing so, you give Qonvey permission to use, modify, distribute, and publicly display the content. You are responsible for making sure that you have the right to submit the content, and that it does not violate any laws or infringe on the rights of others. You are not allowed to submit offensive content, and Qonvey may remove your content at any time without notice.<br />
f. Network Access and Devices. You need to have internet access to use the Services, and you may be charged by your mobile network for this. You are responsible for having the necessary hardware and updating it to use the Services, and Qonvey cannot guarantee that the Services will work on all devices. The Services may also be subject to delays and malfunctions due to internet and electronic communication issues.<br />
9. Payment Using the Services may result in charges for goods and services provided by third- party providers. Qonvey will collect payment on behalf of the provider, and charges are final unless otherwise determined by Qonvey. Charges may include fees, tolls, and taxes. Qonvey can change charges at any time. Tipping is voluntary, and Qonvey does not designate any portion of your payment as a tip or gratuity. You can rate your experience and provide feedback about the third-party provider after receiving their goods or services.</p>

<p>10. Repair or Cleaning Fees. You&#39;re responsible for any damage or cleaning costs to a Third Party Provider&#39;s vehicle or property caused by your use of the Services. If the Third Party Provider reports such damage or cleaning needs and Qonvey verifies it, they can charge your designated payment method to pay for the costs, which are non-refundable.<br />
11. Disclaimers; Limitation of Liability; Indemnity.</p>

<p>a. Disclaimer. Qonvey provides the services &quot;as is&quot; and &quot;as available&quot; and does not make any representations or warranties, express or implied, beyond those expressly stated in these terms. Qonvey disclaims all implied warranties, including but not limited to, merchantability, fitness for a particular purpose, and non-infringement. Qonvey does not guarantee the reliability, timeliness, quality, suitability, or availability of the services or any third-party providers. You agree to assume all risks arising from the use of the services and any services or goods requested through them, to the maximum extent permitted by law.<br />
b. Limitation of Liability. Qonvey is not liable for indirect, incidental, special, exemplary, punitive or consequential damages, including lost profits, lost data, personal injury, or property damage related to the use of the Services. Qonvey is not liable for any damages arising from your use of the Services or any transaction or relationship between you and any Third-Party Provider. Qonvey is not liable for any delay or failure in performance resulting from causes beyond its reasonable control. Qonvey&#39;s total liability to you for all damages, losses and causes of action does not exceed N50,000 (fifty thousand Naira only). Qonvey has no responsibility or liability for any transportation, goods or logistics services provided to you by Third Party Providers, except as expressly set forth in the Terms. The limitations and disclaimer in this section do not limit liability or alter your rights as a consumer that cannot be excluded under applicable law.<br />
c. Indemnity. You agree to indemnify Qonvey and its officers, directors, employees, and agents from all liabilities, expenses, demands, and claims, including attorneys&rsquo; fees, resulting from your use of the Services, your breach of these Terms, your User Content, or infringement of third-party rights, including Third Party Providers.<br />
12. Lost Property. You are responsible for getting your property from Third Party Providers. Qonvey is not liable if the Third-Party Provider doesn&#39;t hand over your property. In the case of undelivered goods, Qonvey will keep your property for three months, after which they can dispose of it if you don&#39;t collect it. </p>

<p>13. Governing Law and Dispute Resolution. These terms shall be governed and construed in accordance with the Laws of the Federal Republic of Nigeria. In the event of dispute, recourse shall be made to arbitration pursuant to the Arbitration and Conciliation Act, 2021 or any court of competent jurisdiction in Nigeria.<br />
14. Other Provisions. Claims of Copyright Infringement. Qonvey&#39;s designated agent should be contacted for copyright infringement claims. Notice may be given through the Services, email, or written communication. These Terms cannot be assigned or transferred without Qonvey&#39;s approval. If any provision is deemed illegal or unenforceable, it will be removed, but the remaining provisions will still apply. These Terms constitute the entire agreement between the parties.</p>


`