import { Box, Heading, SimpleGrid, Image, Container } from '@chakra-ui/react';

const Section8 = () => {
  return (
    <Box style={{ backgroundColor: '#18182D' }} style={{textAlign:window.screen.width <577? 'center':''}}>
      <Box className='bgfooter'>    
        <SimpleGrid columns={{ base: 1, md:1, lg:2}} spacing={10}>
          <Container mt={{base:28, lg: 40, md:28}} mx={{ lg: 10 }}>
            <Heading fontSize={{base: '4xl', md: '4xl', lg: '5xl'}}>Download Qonvey App Now</Heading>
            <SimpleGrid columns={{ base: 1, md:1, lg:3}} mt={5} style={{justifyItems:window.screen.width <577? 'center':''}}>
              <Image src="https://res.cloudinary.com/qonvex-tech/image/upload/v1653431228/Qonvey%20LandingPage/googleplay_sq2djh.png" mt={10}  onClick={()=>window.open('https://play.google.com/store/apps/details?id=com.qonvey')}
            style={{cursor:'pointer'}}alt="download android" />
              <Image src="https://res.cloudinary.com/qonvex-tech/image/upload/v1653431227/Qonvey%20LandingPage/apple_qxbmil.png"ml={{ base: 1, md:1, lg:2}} mt={10} onClick={()=>window.open('https://apps.apple.com/ng/app/qonvey/id6443527939')}  style={{cursor:'pointer'}} alt="download ios" />
            </SimpleGrid>
          </Container>
          <Image src="https://res.cloudinary.com/qonvex-tech/image/upload/v1654075657/Qonvey%20LandingPage/iPhone_X_npjlcq.png" mt={{ base: 5, lg: 10}}  alt="mini iphone" />
        </SimpleGrid>     
      </Box>
    </Box>
  )
}

export default Section8;
