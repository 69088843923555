import { Box, Heading, Image, SimpleGrid, Text, Button } from '@chakra-ui/react';
import { getMobileOperatingSystem } from '../utils/common';

const ThirdSection = () => {
  return (
    <section style={{backgroundColor: '#18182D', marginTop: "-5rem"}}>
      <SimpleGrid columns={{ base: 1, md:2, lg:2}} spacing={10} px={10} py="96px" maxWidth="1440px" mx="auto" >
        <Box px={5} style={{textAlign:window.screen.width <577? 'center':''}}>
          <Heading size="xl" maxW='30rem' mt={{ base: 1, md:1, lg:'36'}}>Dispatch the Qonvey<br /> way.</Heading>
          <Text mt={5}>Get the most of your day knowing your items or product will be delivered by experts. Qonvey is much more than a delivery app, we are giving you the power to control your delivery. </Text>
          <Button mt={5} style={{ backgroundColor: "#1112BA"}} onClick={()=>getMobileOperatingSystem()}>Download Qonvey</Button>
        </Box>
        <Image src="https://res.cloudinary.com/qonvex-tech/image/upload/v1639927196/Qonvey%20LandingPage/mask_moar8v.png" alt="guy in car" />
      </SimpleGrid>
    </section>
  )
}

export default ThirdSection;
