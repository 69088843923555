import './App.css'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./Main";
import Footer from "./Components/Footer";
import Policy from './Components/Policy';
import TermAndCondition from './Components/TermAndCondition';
import { useEffect, useState } from 'react';

function App() {
  const [open,setOpen]=useState(false)
  useEffect(() => {
    setTimeout(() => {
      setOpen(true)  
    }, 5000);
    
    return () => {
      setOpen(false)
    }
  }, [])
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Main open={open} setOpen={setOpen} />} />
          <Route path="privacy-policy" element={<Policy />} />
          <Route path="terms-and-conditions" element={<TermAndCondition />} />
          <Route exact path="/page" element={<Main open={open} setOpen={setOpen}/>} />
          <Route path="/footer" element={<Footer />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
