import {
  SimpleGrid,
  Image,
  Box,
  Heading,
  Text,
  Button,
  Flex,
} from "@chakra-ui/react";
import { getMobileOperatingSystemRider } from "../utils/common";

const FifthSection = () => {
  return (
    <section style={{ backgroundColor: "#18182D" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2 }}
        spacing={10}
        pt={{ lg: 10, base: 10 }}
        maxWidth="1440px"
        mx="auto"
        py="96px"
      >
        <Flex alignItems="center">
          <Box px={12} style={{textAlign:window.screen.width <577? 'center':''}}>
            <Heading size="xl" maxW="30rem">
            Become A Qonveyor
            </Heading>
            <Text mt={5}>
            Join a community of reliable riders as you become a boss and a verified associate of the Qonvey team. 
            </Text>
            <Button mt={5} style={{ backgroundColor: "#1112BA" }} onClick={()=>getMobileOperatingSystemRider()}>
              Get the Rider app
            </Button>
          </Box>
        </Flex>
        <Image
          src="https://res.cloudinary.com/qonvex-tech/image/upload/v1653465426/Qonvey%20LandingPage/Phone11-pro-back_appyuw.png"
          alt="iphone"
          mb="-96px"
          display="block"
          mx="auto"
        />
      </SimpleGrid>
    </section>
  );
};

export default FifthSection;
