import { Box, SimpleGrid, Image, Heading, Text } from '@chakra-ui/react'

const SecondSection = () => {
  return (
    <section className='minibg'>
      <SimpleGrid columns={{ base: 1, md:2, lg:2}} spacing={10} px={10} py="96px" maxWidth="1440px" mx="auto" >
          <Image src="https://res.cloudinary.com/qonvex-tech/image/upload/v1653431204/Qonvey%20LandingPage/Rectangle_6_qnmfvs.png" alt="guy on bike" />
          <Box px={5} style={{textAlign:window.screen.width <577? 'center':''}} >
              <Heading size="xl" maxW='30rem' mt={{ base: 1, md:1, lg:'36'}} textAlign='sm-center'>Get Access to  delivery agents around your location.</Heading>
              <Text mt={5}>We have a wide range network of verified riders on our platform, easily connecting you to one closest to your location.</Text>
          </Box>
      </SimpleGrid>
    </section>
  )
}

export default SecondSection;
