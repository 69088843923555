import { Box, Center, Heading, SimpleGrid, Image, Text } from '@chakra-ui/react'
import React from 'react'

const SixthSection = () => {
    return (
        <Box style={{ backgroundColor: '#0B0B38'}} py={15} pb={10} px={10} style={{textAlign:window.screen.width <577? 'center':''}}>
           <Center color='white'>
                <Heading size="xl"  maxW='30rem' mt={10}>Why Choose Us?</Heading>
            </Center>

            <SimpleGrid columns={{ base: 1, md:2, lg:2}} mx={3} spacing={10} mt={20}>
                <Box>
                    <Heading size="xl" maxW='30rem' mt={10}>Seamless Process.</Heading>
                    <Text mt={2} maxW='30rem'>Our platform design is user-friendly, the mobile app setup is simple and easily accessible. The process of requesting a rider to deliver your pacakge is as easy as A,B C.</Text>

                    <Heading size="xl" maxW='30rem' mt={10}>Quick Delivery.</Heading>
                    <Text mt={2} maxW='30rem'>Enjoy a reliable, on-time delivery service that makes you confident in your choice to use qonvey.</Text>

                    <Heading size="xl" maxW='30rem' mt={10}>Secure Payments.</Heading>
                    <Text mt={2} maxW='30rem'>At Qonvey, we understand and prioritize online security. Our platform guarantees safe and secure payment.</Text>

                    <Heading size="xl" maxW='30rem' mt={10}>Secure Delivery.</Heading>
                    <Text mt={2} maxW='30rem'>We guarantee the safety of your dispatched items. With our exceptional tracking feature, you can keep track of your dispatch items in transit.</Text>
                </Box>
                <Image src="https://res.cloudinary.com/qonvex-tech/image/upload/v1639927124/Qonvey%20LandingPage/Downloader_4_ska8ui.png" mt={10} alt="signature" />
            </SimpleGrid>      
        </Box>
    )
}

export default SixthSection;
