import {
  SimpleGrid,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Image,
  Center
} from '@chakra-ui/react'
import '../App.css';

const FAQs = () => {
  return (
    <section className='FAQbg'>
      <Center fontSize={'3xl'} fontWeight={'extrabold'} py={'20'} px={'10'}>Frequently Asked Questions</Center>
      <SimpleGrid columns={{ base: 1, md:2, lg:2}} spacing={'60'} px={10} mx="auto" >
        <Accordion allowMultiple>
          <AccordionItem style={{ borderTopWidth: '0px'}}>
            <h2>
              <AccordionButton>
                <Box flex='1' textAlign='left' fontSize={'lg'}>
                How does Qonvey work?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} fontSize={'md'}>
            Our delivery process is seamless and straightforward. Users can get started by downloading the app from the google play or App store. By creating a Qonvey account, you can book delivery and get paired with a Qonveyor who will make the delivery. Our platform accommodates a Qonveyor to one user per order.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem  style={{ borderTopWidth: '0px'}}>
            <h2>
              <AccordionButton>
                <Box flex='1' textAlign='left' fontSize={'lg'}>
                How are fares calculated?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} fontSize={'md'}>
            Fares are calculated based on distances, type of delivery, item weight, and category
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem  style={{ borderTopWidth: '0px'}}>
            <h2>
              <AccordionButton>
                <Box flex='1' textAlign='left' fontSize={'lg'}>
                Can I make offline delivery?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} fontSize={'md'}>
            Due to security and fast delivery of your item, we say no to offline delivery.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem style={{ borderTopWidth: '0px'}}>
            <h2>
              <AccordionButton>
                <Box flex='1' textAlign='left' fontSize={'lg'}>
                What are the payment options?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} fontSize={'md'}>
            We have two payment options, either pay by cash or debit card.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem style={{ borderTopWidth: '0px'}}>
            <h2>
              <AccordionButton>
                <Box flex='1' textAlign='left' fontSize={'lg'}>
                Can I track my delivery in real-time?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} fontSize={'md'}>
            Yes, once the Qonveyor starts the trip, you can track your delivery on the app’s home screen.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem style={{ borderTopWidth: '0px', borderBottomWidth: '0px' }}>
            <h2>
              <AccordionButton>
                <Box flex='1' textAlign='left' fontSize={'lg'}>
                Where does Qonvey operate?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} fontSize={'md'}>
            Qonvey currently operates in Lagos as we plan on expanding nationwide in the long run.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        {/* second column */}
        <Image src="https://res.cloudinary.com/qonvex-tech/image/upload/v1655893223/Qonvey%20LandingPage/Group_36798_iijikg.png" mt={{ lg: '32', sm:12}} alt="mini iphone" />

      </SimpleGrid>  
    </section>
  )
}

export default FAQs;
