export const getMobileOperatingSystem = ()=> {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        window.open('https://play.google.com/store/apps/details?id=com.qonvey')
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        window.open('https://play.google.com/store/apps/details?id=com.qonvey')
        return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.open('https://apps.apple.com/ng/app/qonvey/id6443527939')
        return "iOS";
    }
    window.open('https://play.google.com/store/apps/details?id=com.qonvey')
    return "unknown";
}
export const getMobileOperatingSystemRider = ()=> {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        window.open('https://play.google.com/store/apps/details?id=com.qonveydispatch')
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        window.open('https://play.google.com/store/apps/details?id=com.qonveydispatch')
        return "Android";
    }
    // if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    //     window.open('https://apps.apple.com/ng/app/qonvey/id6443527939')
    //     return "iOS";
    // }
    window.open('https://play.google.com/store/apps/details?id=com.qonveydispatch')
    return "unknown";
}